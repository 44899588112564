import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

const TextInput = ({
  handleSendMessage,
  setIsSending,
  isSending,
  scrollToChat,
  followup,
  question,
}) => {
  const [inputValue, setInputValue] = useState('');
  const textareaRef = useRef(null);
  const buttonRef = useRef(null);
  const [textareaWidth, setTextareaWidth] = useState('100%');
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      setTextareaWidth(`calc(100% - ${buttonWidth * 2 + 10}px)`); // Adjust width calculation to account for two buttons
    }
  }, [buttonRef.current?.offsetWidth]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    resizeTextarea();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab' && !isSending && inputValue.trim() === '') {
      event.preventDefault();
      setInputValue(followup);
      textareaRef.current.focus();
    }
    if (event.key === 'Enter' && !event.shiftKey && !isSending) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      const computedStyle = window.getComputedStyle(textarea);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const marginTop = parseFloat(computedStyle.marginTop);
      const marginBottom = parseFloat(computedStyle.marginBottom);
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;

      const totalMargin = marginTop + marginBottom;
      const maxLines = Math.floor((textarea.clientHeight - totalMargin) / lineHeight);
      if (maxLines >= 7) {
        textarea.style.height = `${7 * (lineHeight + totalMargin)}px`;
        textarea.style.overflowY = 'hidden';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  const handleTextareaClick = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setScrollPosition(window.scrollY);
      window.addEventListener('resize', handleResize);
    }
  };

  const handleResize = () => {
    setTimeout(() => {
      textareaRef.current.scrollIntoView(false);
    }, 0);
    window.removeEventListener('resize', handleResize);
    window.scrollTo(0, scrollPosition);
  };

  const abortControllerRef = useRef(null);

  const handleSubmit = async (event) => {
    const textarea = textareaRef.current;
    abortControllerRef.current = new AbortController();

    event.preventDefault();
    if (inputValue.trim() !== '') {
      handleSendMessage(question, inputValue, abortControllerRef.current);
      setInputValue('');
      textarea.style.height = 'auto';
      scrollToChat();
    }
  };

  const stopStreaming = () => {
    if (abortControllerRef.current && isSending) {
      abortControllerRef.current.abort();
      setIsSending(false);
    }
  };

  return (
<div className='shadow-full relative border border-white rounded-full h-12 backdrop-blur-sm flex items-center'>

<form onSubmit={handleSubmit} className='flex items-center w-full h-full'>

<textarea
  ref={textareaRef}
  id='input-field'
  placeholder={followup}
  value={inputValue}
  onKeyDown={handleKeyDown}
  onChange={handleChange}
  autoComplete='off'
  rows='1'
  className='bg-transparent overflow-y-hidden appearance-none w-full py-2 px-3 leading-tight focus:outline-none box-border placeholder-gray-400 resize-none'
  style={{ width: textareaWidth, lineHeight: '1.5', marginTop: 'auto', marginBottom: 'auto', paddingLeft: '20px' }} // Increased paddingLeft
  onClick={handleTextareaClick}
/>
        
        <Button
            ref={buttonRef}
            type='submit'
            onClick={stopStreaming}
            sx={{
              backgroundColor: 'transparent',
              position: 'absolute',
              bottom: '-0.1rem',
              right: 45, // Adjusted to make space for the duplicate button
              background: 'transparent',
              borderRadius: '1.5rem',
              height: '47px', // Set the height to match the text input box
            }}
            endIcon={
              isSending ? (
                <StopCircleIcon
                  sx={{
                    fontWeight: '600',
                    transition: 'transform 0.2s ease-in-out',
                    transform: 'scale(1.5)',
                    '&:hover': {
                      transform: 'scale(1.35)',
                    },
                    marginRight: '0.35rem',
                    marginY: '0.125rem',
                  }}
                  className='font-semibold text-white'
                />
              ) : (
                <SendIcon
                  sx={{
                    fontWeight: '600',
                    transition: 'transform 0.2s ease-in-out',
                    transform: 'scale(1.15)',
                    '&:hover': {
                      transform: 'scale(1.45)',
                    },
                    marginRight: '0rem',
                    marginY: '0rem',
                  }}
                  className='font-semibold text-white'
                />
              )             
          }></Button>
          <Button
            type='button' // Changed to 'button' to avoid submitting the form
            onClick={() => console.log('Microphone button clicked')}
            sx={{
              backgroundColor: 'transparent',
              position: 'absolute',
              bottom: '-0.1rem',
              right: 0,
              background: 'transparent',
              borderRadius: '1.5rem',
              height: '47px', // Set the height to match the text input box
            }}
            endIcon={
              <FontAwesomeIcon
                icon={faMicrophone}
                sx={{
                  fontWeight: '600',
                  transition: 'transform 0.2s ease-in-out',
                  transform: 'scale(1.15)',
                  '&:hover': {
                    transform: 'scale(1.25)',
                  },
                  marginRight: '0.25rem',
                  marginY: '0.125rem',
                }}
                className='font-semibold text-white'
              />
          }></Button>
      </form>
    </div>
  );
};

export default TextInput;
