import React, { useRef, useEffect } from 'react';
import chatbot from './../pictures/Bot.png'; // Import this icon to @mui
import FormatMessages from './FormatMessages';

const ChatWindow = ({ messages }) => {
  const chatContentRef = useRef(null);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      className='relative flex flex-col overflow-y-auto border border-white border-50px {
    border-width: 3px; /* Example for a 3px border width */
} bg-black/5 h-[50dvh]  text-black font-light rounded-3xl'
      style={{
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'thin',
      }}
    >
      <div className='p-4 flex-grow overflow-y-auto' ref={chatContentRef}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-2 ${message.isUser ? 'text-right' : 'text-left'}`}
          >
            {message.isUser ? (
              <div className='flex flex-col items-end'>
                <p
                  className='text-left bg-white break-words max-w-full mb-5'
                  style={{ padding: '0.5rem 1.2rem', borderRadius: '1rem' }} // Adjust horizontal padding here
                >
                  <FormatMessages text={message.text} />
                </p>
              </div>
            ) : (
              <div className='flex flex-col items-start'>
                <p
                  className='text-left break-words bg-white bg-opacity-50 max-w-full mb-5 font-light'
                  style={{ padding: '0.5rem 1.2rem', borderRadius: '1rem' }} // Adjust horizontal padding here
                >
                  <FormatMessages text={message.text} />
                </p>
              </div>
            )}
          </div>
        ))}

        <div style={{height: '15px'}}> </div>
      </div>
    </div>
  );
};

export default ChatWindow;
