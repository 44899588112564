import React, { useState, useRef } from 'react';
import LandingPage from './components/LandingPage';
import SelectingPersona from './components/SelectingPersona';
import DontFuckUp from './components/DontFuckUp';
import ChatWindow from './components/ChatWindow';
import TextInput from './components/TextInput';
import './index.css'; // Adjust the path as necessary
import OurMission from './components/OurMission';
import Footer from './components/Footer';
import CreatePersonaInput from './components/CreatePersonaInput';


function App() {
  const [messages, setMessages] = useState([]);
  const chatWindowRef = useRef(null);
  const [conversation_id, setConvoId] = useState(null);
  const [status, setStatus] = useState('start');

  const [personaName, setPersonaName] = useState(null);
  const [personaGoal, setPersonaGoal] = useState(null);

  const [convoHidden, setConvoHidden] = useState(true);

  const [promptShow, setPromptShow] = useState(false);

  const [hasFeedback, setHasFeedback] = useState(false);

  // for the persona selector
  const [input, setInput] = useState('');
  const [input2, setInput2] = useState('');

  const handleSendMessage = (question, message, abortController) => {
    const newMessage = {
      text: message,
      isUser: true,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Make the AJAX request
    fetch('http://localhost:5001/api/say', {
      method: 'POST', // Adjust method as needed (GET, POST, etc.)
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        conversation_id: conversation_id, 
        message: message,
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Assuming response is JSON; adjust if different
    })
    .then(data => {
      // Handle successful response
      const botResponse = {
        text: data.response, // Adjust property name based on response structure
        isUser: false,
      };

      // Update state with bot's response
      setMessages((prevMessages) => [...prevMessages, botResponse]);
      scrollToChat();
    })
    .catch(error => {
      // Handle error
      console.error('Error fetching data:', error);
      // Optionally update state or handle error UI/UX
    });
  };

  const scrollToChat = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };




  const convo_select = (persona) => {

    setConvoHidden(true);

    // clear the messages
    setMessages([]);
    
    // create the new conversation
    fetch('http://localhost:5001/api/new_conversation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "persona": persona,
        "create_persona": false,
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((data) => {
      setConvoId(data._id);
      setStatus('selected');

      setPersonaName(data.persona);
      setPersonaGoal(data.challenge);

      setConvoHidden(false);
    });

  }



  return (
    <div classname="">
    <div className= "px-0 normalcontainer">
    <DontFuckUp /> {/* Use the DontFuckUp component */}
  </div>
    <div className="App bg-gradient-to-r from-white-100 to-purple-300">
      
      
      
      
    

      {/* bg-gradient-to-r from-violet-200 to-pink-200 */}

      <div className="App bg-gradient-to-r from-purple-100 to-blue-100  pt-20 ">
      <div className="normalcontainer">
        <LandingPage convo_select={convo_select} setPromptShow={setPromptShow} />
      </div>
    


      {(promptShow && (
        <div className=" items-left mt-[-110px] pb-40  ">
          <CreatePersonaInput /> 
        </div>
      ))}
      


      {!convoHidden && (
        <div className=" pb-20 px-48 mb-32">
          <div className="grid grid-cols-2 gap-0 ">
        

            <div className="chat-container items-center">
              <div className=" items-left text-left mb-8">
              <p className="font-bold text-[20pt]">
                You are now conversing with {personaName ? personaName : '______'}.
              </p>
              {personaName && personaGoal && (
                <p>
                  Convince them that "{personaGoal}"
                </p>
              )}
                <p className="">
                  Facilitate, depolarize, be kind and consider the Tipps & Tricks and Disclaimer!
                </p>
              </div>



              <div className="" ref={chatWindowRef}>
                <ChatWindow messages={messages} />
              </div>

              <div className="text-input-container mt-[-49px] items-center justify-center w-[calc(100%)]">
                <TextInput handleSendMessage={handleSendMessage} scrollToChat={scrollToChat} />
              </div>

            </div>

            {(hasFeedback && (

              <div className="chat-container items-center pl-20">
            
                <div className=" items-left text-left mb-8">
                    <p className=" font-bold text-[20pt] mb-12 ">
                      Tips & Tricks 
                    </p>

                  
                  <ul className="list-disc list-inside text-lg text-black md:text-lg lg:text-lg space-y-2 mb-24 ">
                    <li>A safe space for people to try out new things  </li>
                    <li>Practice communication and pick </li>
                    <li>Aiming for more peace through self-education  </li>
                  </ul>

                  <p className=" font-bold text-[20pt]  mb-12 ">
                    Disclaimers
                  </p>
                  <ul className="list-disc list-inside space-y-2">
                    <li>SaaS for recruitment interviews for companies</li>
                    <li>Machine-readable database for social </li>
                    <li>LeetCode for the social realm </li>
                  </ul>


                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className=" pb-10 normalcontainer " ref={chatWindowRef}>  
        <OurMission />
      </div>


      <Footer />


      </div>

    </div>
</div>
    

  );
}

export default App;
