import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone ,faLock,faBolt } from '@fortawesome/free-solid-svg-icons';



const CreatePersonaInput = ({ input, setInput, input2, setInput2 }) => {
  const textareaRef = useRef(null);
  const textareaRef2 = useRef(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
  };

  const handleChange2 = (event) => {
    const value = event.target.value;
    setInput2(value);
  };

  const handleKeyDown2 = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
  };


  const buttonBaseClass = "bg-gradient-to-r mx-1 text-center h-9 flex items-center justify-center relative resize-none text-white p-3 rounded-full";
  const defaultClass = "from-pink-600 to-pink-400";
  const selectedClass = "from-blue-500 to-violet-500";
  const [selectedLevel, setSelectedLevel] = useState(null);

  return (

    <div className="w-full px-4 py-40 md:px-12 md:py-8 lg:px-48 lg:py-0">





      <div className="shadow-full h-9 rounded-3xl backdrop-white w-1/2" style={{ backgroundColor: 'rgba(255, 255, 255, 1.0)' }}>
        <form className="flex items-left w-full">
          <textarea
            ref={textareaRef}
            id="input-field"
            placeholder="I am..."
            value={input}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            maxLength={30}
            autoComplete="off"
            rows="1"
            className="bg-transparent overflow-y-hidden appearance-none w-full py-2 px-3 leading-tight focus:outline-none box-border placeholder-grey-400 resize-none"
          />
        </form>

        
      </div>

      <div className="shadow-full relative rounded-3xl h-9 backdrop-white w-1/2 mt-4" style={{ backgroundColor: 'rgba(255, 255, 255, 1.0)' }}>
        <form className="flex items-center w-full">
          <textarea
            ref={textareaRef2}
            id="input-field-2"
            placeholder="Convince me that..."
            value={input2}
            onKeyDown={handleKeyDown2}
            onChange={handleChange2}
            maxLength={70}
            autoComplete="off"
            rows="1"
            className="bg-transparent overflow-y-hidden appearance-none w-full py-2 px-3 leading-tight focus:outline-none box-border placeholder-grey-400 resize-none"
          />
        </form>
      </div>



<div className="flex items-center my-4">
    <button className="bg-gradient-to-r text-center w-1/4 h-9 flex items-center justify-center   from-pink-600 to-pink-400 text-white p-3 rounded-full hover:from-blue-500 hover:to-violet-500">            
        Create Persona
    </button>
    
    <div className="items-left w-1/4 ">
    <div className="ml-7">
    <div className="flex items-center space-x-2">

      
  {['Level 1', 'Level 2', 'Level 3'].map((level, index) => (
    <button
      key={level}
      className={`${buttonBaseClass} ${selectedLevel === index ? selectedClass : defaultClass}`}
      onClick={() => setSelectedLevel(index)}
    >
      {level}
    </button>
  ))}
</div>

      </div>
      </div>

</div>








    </div>
  );
};

export default CreatePersonaInput;
